<template>
  <div>
    <calendarTitle />
    <calendarColor />
    <calendarButtonText />
    <calendarPreview />
    <hr style="margin: 40px 0;" />
    <div>
      <calendarInfotext />
    </div>
    <saveBtn
      class="saveBtn"
      :callback="function () { checkData(); }"
    />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  components: {
    calendarTitle() {
      return import("@/components/business/styling/title");
    },
    calendarColor() {
      return import("@/components/business/styling/color");
    },
    calendarButtonText() {
      return import("@/components/business/styling/buttonText");
    },
    calendarPreview() {
      return import("@/components/business/styling/preview");
    },
    calendarInfotext() {
      return import("@/components/business/infotext/index.vue");
    }
  },
  extends: def,
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    nextStep() {
      if (this.$store.state.business.unsaved.type === "services") {
        this.$router.push({ name: "services" });
        return false;
      }
      this.$router.push({ name: "time" });
    },
    checkData() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      if (!data.title) {
        return false;
      }
      this.setSave();
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
.saveBtn
  margin-top: 20px
</style>
